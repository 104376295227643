import debounce from 'lodash/debounce';
import { memo, useState, useEffect, useCallback, useContext } from 'react';

import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { getJobFormUrl } from 'lib/utils/Environment';
import { extractPostcode } from 'shared/utils/helpers';

import {
  HeroStyled,
  TitleStyled,
  LabelStyled,
  AutoCompleteStyled,
  ButtonStyled,
  HeroContentStyled,
  InputWrapperStyled,
  ServiceDropDownStyled,
  DropDownStyled,
  LocationAutoCompleteStyled
} from './styled/HeroDropDownStyled';

const weddingCategories = [
  {
    label: 'Catering',
    value: '57'
  },
  {
    label: 'Celebrant',
    value: '162'
  },
  {
    label: 'DJ',
    value: '261'
  },
  {
    label: 'Entertainer',
    value: '262'
  },
  {
    label: 'Car Hire',
    value: '6'
  },
  {
    label: 'Florist',
    value: '198'
  },
  {
    label: 'Hair Stylist',
    value: '308'
  },
  {
    label: 'Makeup Artist',
    value: '309'
  },
  {
    label: 'Photographer',
    value: '8'
  },
  {
    label: 'Planner',
    value: '160'
  },
  {
    label: 'Videographer',
    value: '9'
  }
];

const JOB_FORM_URL = getJobFormUrl();
const HeroDropDown = () => {
  const { controller } = useContext(JobFormControllerContext);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocationInput, setSelectedLocationInput] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<{ label: string; value: string } | null>(null);
  const [lastSelectedLocation, setLastSelectedLocation] = useState(null);
  const [dropDownValue, setDropDownValue] = useState('');
  const fethLocations = debounce(async (value) => {
    const options = await fetch(
      `${JOB_FORM_URL}/api/autocomplete/v1/locations/search?query=${encodeURIComponent(
        value
      )}`
    ).then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    });
    setLocationOptions(options);
  }, 300);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value && value.trim().length > 0) {
      fethLocations(value);
    }
    setSelectedLocationInput(value);
    setLastSelectedLocation(null);
  };

  const handleJobFormLaunch = useCallback(async () => {
    const locationId = lastSelectedLocation?.id;
    const locationName = lastSelectedLocation?.value;
    const categoryId = selectedCategory?.value;
    const categoryName = selectedCategory?.label;
    const postcode = extractPostcode(locationName);
    await controller?.open({
      categoryId,
      locationName,
      locationId,
      postcode,
      categoryName
    });
  }, [controller, lastSelectedLocation, selectedCategory]);

  useEffect(() => {
    if (!selectedCategory?.value || !lastSelectedLocation) {
      return;
    }
    handleJobFormLaunch();
  }, [selectedCategory?.value, lastSelectedLocation, handleJobFormLaunch]);

  const handleSelectLocation = (option) => {
    const selectedLocationInput = locationOptions.find((item) => item.value === option.value);
    setSelectedLocationInput(option.value);
    setLastSelectedLocation(selectedLocationInput);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleCategorySelect = (option) => {
    setSelectedCategory(option);
    setDropDownValue(option.value);
  };

  return (
    <HeroStyled id="site_form">
      <HeroContentStyled>
        <TitleStyled>Wedding planning made easy</TitleStyled>
        <InputWrapperStyled>
          <ServiceDropDownStyled>
            <LabelStyled htmlFor="dropdown-hero">
              What service do you need?
            </LabelStyled>
            <DropDownStyled
              id="dropdown-hero"
              value={dropDownValue}
              options={weddingCategories}
              handleChange={handleCategorySelect}
              $isSelected={selectedCategory?.value}
              placeholder="Please select a category"
            />
          </ServiceDropDownStyled>
          <LocationAutoCompleteStyled>
            <LabelStyled htmlFor="location-hero">
              Where do you need it?
            </LabelStyled>
            <AutoCompleteStyled
              type="text"
              id="location-hero"
              items={locationOptions.map(({ value }) => ({ name: value, value }))}
              onChange={handleChange}
              onFocus={handleFocus}
              onSelect={handleSelectLocation}
              placeholder="e.g. Sydney, 2000"
              value={selectedLocationInput}
            />
          </LocationAutoCompleteStyled>
          <ButtonStyled
            label="get free quotes"
            kind="secondary"
            onClick={handleJobFormLaunch}
          />
        </InputWrapperStyled>
      </HeroContentStyled>
    </HeroStyled>
  );
};

export default memo(HeroDropDown);
