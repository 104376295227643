import Head from 'next/head';
import PropTypes from 'prop-types';

import { getCloudFrontImageUrl } from 'shared/utils/cloudFrontImageResize';
import { useCloudFrontImageResizeURL } from 'shared/utils/hooks';

const propTypes = {
  bottomHead: PropTypes.node,
  metaDescription: PropTypes.string,
  pageShareImg: PropTypes.string,
  pageTitle: PropTypes.string,
  pageUrl: PropTypes.string.isRequired,
  topHead: PropTypes.node,
  tracking: PropTypes.shape({
    environment: PropTypes.string,
    deploymentVersion: PropTypes.string,
    locationId: PropTypes.string,
    categoryId: PropTypes.string,
    categoryName: PropTypes.string,
    zoneId: PropTypes.string,
    region_id: PropTypes.string,
    state_id: PropTypes.string
  })
};

const HeadContent = ({
  pageUrl,
  pageTitle = 'Wedding Services Near Me | Wedding.com.au',
  metaDescription = 'Wedding.com.au is the easiest way to hire trusted wedding providers, from DJ’s to Makeup Artists. Post your requirements and compare quotes for free.',
  pageShareImg = getCloudFrontImageUrl('static/wedding/wedding-share.png', 'oneflare'),
  tracking = {},
  bottomHead = null,
  topHead = null
}) => {
  const [getCloudFrontImage] = useCloudFrontImageResizeURL({ defaultBucket: 'oneflare' });

  const getImage = (size) => getCloudFrontImage({ key: `static/wedding/${size}.png` });

  return (
    // Page landing datalayer script is declared as a helmet prop
    // because it needs to be fired before the GTM start event.
    <Head>
      <title>{pageTitle}</title>
      {topHead}
      <link rel="canonical" href={pageUrl} />
      <link rel="icon" type="image/x-icon" href={getImage('57x57')} sizes="57x57" />
      <link rel="icon" type="image/x-icon" href={getImage('72x72')} sizes="72x72" />
      <link rel="icon" type="image/x-icon" href={getImage('76x76')} sizes="76x76" />
      <link rel="icon" type="image/x-icon" href={getImage('114x114')} sizes="114x114" />
      <link rel="apple-touch-icon" type="image/x-icon" href={getImage('120x120')} sizes="120x120" />
      <link rel="icon" type="image/x-icon" href={getImage('144x144')} sizes="144x144" />
      <link rel="apple-touch-icon" type="image/x-icon" href={getImage('152x152')} sizes="152x152" />
      <link rel="apple-touch-icon" type="image/x-icon" href={getImage('180x180')} sizes="180x180" />
      {metaDescription && <meta name="description" content={metaDescription} />}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={pageShareImg} />
      <meta property="og:title" content={pageTitle} />
      {metaDescription && <meta property="og:description" content={metaDescription} />}
      <script>
        window.dataLayer = window.dataLayer || [];
      </script>
      {tracking.environment
        && (
          <script
            type="text/javascript"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: `window.dataLayer.push(${JSON.stringify(tracking)});` }}
          />
        )}
      {bottomHead}
    </Head>
  );
};

HeadContent.propTypes = propTypes;

export default HeadContent;
