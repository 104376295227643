import AutoComplete from '@oneflare/flarekit/lib/components/AutoComplete';
import Button from '@oneflare/flarekit/lib/components/Button';
import Dropdown from '@oneflare/flarekit/lib/components/Dropdown';
import H1 from '@oneflare/flarekit/lib/components/H1';
import Label from '@oneflare/flarekit/lib/components/Label';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const HeroStyled = styled.section`
  align-items: center;
  background-image: url("https://cdn.oneflare.com/static/wedding/wedding-bg.jpg");
  background-position: center center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  min-height: 530px;
  padding: 40px 20px;
  position: relative;
  margin: 0 auto;

  ${media.mdUp`
    padding: 60px 0;
  `}

  ${media.lgUp`
    padding: 80px 0;
  `}
`;

export const HeroContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  top: 54px;
  position: absolute;
  width: 100%;
  padding: 0 20px;

  ${media.mdUp`
    max-width: 734px;
    top: 54px;
    position: absolute;
    padding: 0;
  `}

  ${media.lgUp`
    top: 45px;
  `}
`;

export const TitleStyled = styled(H1)`
  color: ${({ theme }) => theme.color.tertiary};
  font-size: 28px;
  margin-bottom: 24px;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  text-align: center;

  ${media.lgUp`
    margin-bottom: 31px;
    font-size: 40px;
  `}
`;

export const ServiceDropDownStyled = styled.div`
  ${media.mdUp`
    width: 319px;
  `}
`;

export const LocationAutoCompleteStyled = styled.div`
  ${media.mdUp`
    width: 319px;
  `}
`;

export const InputWrapperStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 2px rgba(128, 130, 133, 0.2);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 32px;

  ${media.mdUp`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const LabelStyled = styled(Label)`
  color: ${({ theme }) => theme.color.tertiary};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
`;

export const AutoCompleteStyled = styled(AutoComplete)`
  margin: 8px 0 24px;
  width: 100%;

  .AutoComplete__input {
    font-family: ${({ theme }) => theme.font.primaryRegular};
    font-size: 16px;
    height: 48px;
    text-align: left;

    ::placeholder {
      color: ${({ theme }) => theme.color.aluminium};
    }

    :focus {
      box-shadow: none;
    }

  }

  ul {
    font-family: ${({ theme }) => theme.font.primaryRegular};
    z-index: ${({ theme }) => theme.zIndex[9]};
  }

  li {
    font-family: ${({ theme }) => theme.font.primaryRegular};
    text-align: left;
  }
`;

export const ButtonStyled = styled(Button)`
  font-size: 18px;
  height: 60px;
  width: 100%;
  text-transform: uppercase;
`;

export const DropDownStyled = styled(Dropdown)`
  margin: 8px 0 24px;

  #dropdown-hero {
    height: 48px;
    padding: 12px 14px;
    color: ${({ $isSelected, theme }) => ($isSelected ? theme.color.gunMetal : theme.color.aluminium)};
  }
`;
